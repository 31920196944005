import "./App.css";
import CheckMuted from "./components/CheckMutedTool";
import CustomQuery from "./components/CustomQueryTool";
import HashTagTool from "./components/HashtagTool";
import MenuBar from "./components/MenuBar";
import GenerateKeys from "./components/GenerateKeysTool";
import bech32HexTool from "./components/Bech32HexTool";
import React, { useState } from "react";

function App() {
  const tabs = [
    { id: "checkMuted", label: "See Who Muted You?" },
    { id: "hashtag", label: "Search Hashtags" },
    { id: "customQuery", label: "Run Your Own Query" },
    { id: "generateKeys", label: "Generate New Keys" },
    { id: "bech32Hex", label: "Bech32 To Hex" },
  ];

  const tabContent = {
    hashtag: HashTagTool,
    checkMuted: CheckMuted,
    customQuery: CustomQuery,
    generateKeys: GenerateKeys,
    bech32Hex: bech32HexTool,
  };

  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [expanded, setExpanded] = useState(true);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <h1 style={{ color: "yellow", fontFamily: ["Courier New", "monospace"] }}>
        Toolstr
      </h1>
      <div className="app-container">
        <MenuBar
          tabs={tabs}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          expanded={expanded}
          handleExpandClick={handleExpandClick}
        />
        <div className={`content-container ${expanded ? "" : "collapsed"}`}>
          {(() => {
            const ActiveComponent = tabContent[activeTab];
            return <ActiveComponent />;
          })()}
        </div>
      </div>
    </div>
  );
}

export default App;
