import React, { useState } from "react";
import "./HashTagTool.css";

import { SimplePool } from "nostr-tools";
import ContentCard from "../ContentCard";

const HashTagTool = (props) => {
  const [hashtag, setHashtag] = useState(null);
  const [notes, setNotes] = useState([]);
  const [kind0List, setKind0List] = useState([]);

  const relays = [
    "wss://relay.damus.io/",
    "wss://offchain.pub/",
    "wss://nos.lol/",
    "wss://relay.nostr.wirednet.jp/",
    "wss://nostr.wine/",
  ];

  const handleChange = (event, setter) => {
    setter(event.target.value);
  };

  const inputs = [
    {
      label: "HashTag",
      handler: (event) => {
        handleChange(event, setHashtag);
      },
      variable: hashtag,
    },
  ];

  const handleClick = async () => {
    const relayPool = new SimplePool();
    const filters = {
      limit: 10,
    };
    if (hashtag && hashtag.length !== 0) filters["#t"] = [hashtag];

    console.log("filters", filters);
    let notes = await relayPool.list(relays, [filters]);
    console.log("muted", notes);
    setNotes(notes);
    const kind0Filters = {
      kinds: [0],
      authors: notes.map((note) => {
        return note.pubkey;
      }),
    };
    console.log("kind0Filters", kind0Filters);
    const kind0Result = await relayPool.list(relays, [kind0Filters]);
    setKind0List(kind0Result);
    //setMuteList(muted);
    // console.log("muteList", muteList);
  };

  return (
    <div className="matrix-input-container">
      <div className="inputWrapper">
        {inputs.map((input) => {
          return (
            <div>
              <input
                type="text"
                text="npub..."
                placeholder={input.label}
                onChange={input.handler}
                className="matrix-input"
              />
            </div>
          );
        })}
      </div>
      <button className="play-game-button matrix-style" onClick={handleClick}>
        See Results
      </button>
      <div className="results">
        {notes.map((note) => {
          //console.log("note", note);
          return (
            <ContentCard
              key={note.id}
              pubKey={note.pubkey}
              content={note.content}
              kind0List={kind0List}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HashTagTool;
