import "./MenuBar.css";

const MenuBar = ({
  tabs,
  activeTab,
  handleTabClick,
  expanded,
  handleExpandClick,
}) => {
  const toggleMenu = () => {
    handleExpandClick();
  };

  return (
    <div className={`matrix-menubar ${expanded ? "" : "collapsed"}`}>
      <div style={{ position: "relative", padding: "20px" }}>
        <button className="menu-toggle" onClick={toggleMenu}>
          {expanded ? <span> &lt;&lt; </span> : <span>&gt;&gt;</span>}
        </button>
      </div>
      {expanded && (
        <div>
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`matrix-menubar-tab ${
                activeTab === tab.id ? "active" : ""
              }`}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.label}
            </div>
          ))}
        </div>
      )}
      <div style={{ position: "absolute", bottom: 0 }}>
        <a className="matrix-button" href="lightning:wshakesp@getalby.com">
          ⚡ Support the dev
        </a>
      </div>
    </div>
  );
};

export default MenuBar;
