import React, { useState } from "react";
import "../HashtagTool/HashTagTool.css";

import { SimplePool } from "nostr-tools";
import ContentCard from "../ContentCard";

const CustomQuery = (props) => {
  const [query, setQuery] = useState(null);
  const [isValidJson, setIsValidJson] = useState(true);
  const [notes, setNotes] = useState([]);
  const [kind0List, setKind0List] = useState([]);

  const relays = [
    "wss://relay.damus.io/",
    "wss://offchain.pub/",
    "wss://nos.lol/",
    "wss://relay.nostr.wirednet.jp/",
    "wss://nostr.wine/",
  ];

  const handleInputChange = (event, setter) => {
    setQuery(event.target.value);
  };

  const handleClick = async () => {
    const relayPool = new SimplePool();
    //console.log("textt", nip19.decode());
    try {
      JSON.parse(query);
      setIsValidJson(true);
    } catch (error) {
      setIsValidJson(false);
      return;
    }
    const filters = JSON.parse(query);
    console.log("filters", query);
    let notes = await relayPool.list(relays, [filters]);
    console.log("notes", notes);
    setNotes(notes);
    const kind0Filters = {
      kinds: [0],
      authors: notes.map((note) => {
        return note.pubkey;
      }),
    };
    const kind0Result = await relayPool.list(relays, [kind0Filters]);
    setKind0List(kind0Result);
    //setMuteList(muted);
    // console.log("muteList", muteList);
  };

  return (
    <div className="matrix-input-container">
      <div className="inputWrapper">
        <textarea
          onChange={handleInputChange}
          placeholder="Enter JSON here..."
          className="matrix-input"
          rows={query?.split("\n").length || 5}
        />
      </div>
      {!isValidJson && <p style={{ color: "red" }}>Invalid JSON</p>}
      <button className="play-game-button matrix-style" onClick={handleClick}>
        See Results
      </button>
      <div className="results">
        {notes.map((note) => {
          return (
            <ContentCard
              key={note.id}
              pubKey={note.pubkey}
              content={note.content}
              kind0List={kind0List}
              note={JSON.stringify(note, null, 2)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CustomQuery;
