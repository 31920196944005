import React, { useEffect, useState } from "react";
import "./ContentCard.css";

const ContentCard = ({ pubKey, content, note, kind0List }) => {
  const [nip05, setNip05] = useState(null);
  const [displayName, setDisplayName] = useState(null);

  const renderRawJSON = () => {
    return note && note.length !== 0;
  };

  useEffect(() => {
    //console.log("Everything", pubKey, content, note, kind0List);
    //console.log("kind0List", kind0List);
    const userKind0 = (kind0List || []).find((pub) => {
      //console.log("pub", pub.pubkey, pubKey);
      return pub.pubkey === pubKey;
    });
    console.log("userKind0", userKind0);
    try {
      let userInfo = JSON.parse(userKind0.content);
      setNip05(userInfo["nip05"]);
      setDisplayName(userInfo["display_name"]);
      console.log("userInfo", userInfo);
    } catch {
      return;
    }
  }, [kind0List, pubKey]);
  return (
    <div className="matrix-card">
      {!renderRawJSON() && (nip05 || displayName) && (
        <div style={{ color: "yellow" }}>
          <h2 className="matrix-card-title">{displayName}</h2>
          <p> {nip05}</p>
        </div>
      )}
      {!renderRawJSON() && !(nip05 || displayName) && (
        <h2 style={{ color: "yellow" }}>
          {" "}
          {(pubKey || "").substring(0, 10) + "..."}
        </h2>
      )}
      <p className="matrix-card-content">
        {renderRawJSON() ? (
          <pre
            style={{
              maxWidth: "100%",
              overflowWrap: "break-word",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {note}
          </pre>
        ) : (
          <pre
            style={{
              maxWidth: "100%",
              overflowWrap: "break-word",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {content}
          </pre>
        )}
      </p>
    </div>
  );
};

export default ContentCard;
