import { nip19 } from "nostr-tools";
import { useState } from "react";
import ContentCard from "../ContentCard";

const Bech32HexTool = (props) => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState({});
  const [error, setError] = useState(false);
  const inputHandler = (event) => {
    setInput(event.target.value);
  };

  const handleClick = () => {
    try {
      const result = nip19.decode(input);
      setOutput(result);
      setError(false);
    } catch (e) {
      setError(true);
      setOutput({});
    }
  };
  return (
    <div
      style={{
        display: "flex",
        direction: "column",
        maxHeight: "100%",
      }}
      className="matrix-input-container"
    >
      <div className="inputWrapper">
        <input
          style={{ padding: "10px" }}
          type="text"
          placeholder="npub/nsec/noteid/nevent/naddr.."
          onChange={inputHandler}
          className="matrix-input"
        />
      </div>
      <button className="play-game-button matrix-style" onClick={handleClick}>
        Convert From Bech32 to Hex
      </button>
      {error && <p style={{ color: "red" }}>Invalid Bech32</p>}
      {Object.keys(output).length !== 0 && (
        <div className="results">
          <ContentCard note={JSON.stringify(output, null, 2)} />
        </div>
      )}
    </div>
  );
};

export default Bech32HexTool;
