import { generatePrivateKey, getPublicKey, nip19 } from "nostr-tools";
import { useState } from "react";
import ContentCard from "../ContentCard";
import "../HashtagTool/HashTagTool.css";

const GenerateKeysTool = (props) => {
  const [content, setContent] = useState("");
  const handleClick = () => {
    const privateKey = generatePrivateKey();
    const pubKey = getPublicKey(privateKey);
    const content = `npub: ${nip19.npubEncode(
      pubKey
    )}\n\nnsec: ${nip19.nsecEncode(privateKey)}`;
    setContent(content);
  };
  return (
    <div style={{ maxWidth: "80%" }} className="matrix-input-container">
      <button className="play-game-button matrix-style" onClick={handleClick}>
        Generate Keys
      </button>
      {content.length !== 0 && <ContentCard title="" content={content} />}
    </div>
  );
};

export default GenerateKeysTool;
